import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Lo sentimos, esta página no existe" />
    <div className="py-12">
      <h1 className="text-center text-2xl">404: No existe</h1>
      <p className="text-center">Lo sentimos, esta página no existe</p>
    </div>
  </Layout>
);

export default NotFoundPage;
